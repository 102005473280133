<template>
    <!--    商品计划销售流水-->
    <div class="GoodsProject" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form :inline="true">
                <el-form-item label="机构组">
                    <el-select
                        placeholder="机构组"
                        v-model="form.deptGroupCode"
                        @change="handleChangeGroup"
                        size="small"
                    >
                        <el-option :value="g.code" :key="g.code" :label="g.name" v-for="g in meta.deptGroups" />
                    </el-select>
                </el-form-item>
                <el-form-item label="机构">
                    <el-select v-model="form.deptCode" filterable size="small">
                        <el-option label="请选择" value="" />
                        <el-option v-for="item in meta.depts" :label="item.name" :value="item.code" :key="item.code" />
                    </el-select>
                </el-form-item>
                <el-form-item label="商品计划">
                    <el-select placeholder="商品计划" v-model="form.merchandisingCode" size="small">
                        <el-option value="" label="请选择" />
                        <el-option :value="g.code" :key="g.code" :label="g.name" v-for="g in meta.merchandising" />
                    </el-select>
                </el-form-item>
                <el-form-item label="消费会员">
                    <el-input v-model="form.mobile" placeholder="会员名称或电话" size="small" />
                </el-form-item>
                <el-form-item label="销售日期">
                    <el-date-picker
                        v-model="sellDate"
                        type="daterange"
                        unlink-panels
                        size="small"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd"
                    />
                </el-form-item>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button
                type="primary"
                size="small"
                @click="handleQuery"
                v-if="hasPrivilege('menu.report.merchandising.flow.open')"
                >查询
            </el-button>
            <!-- <el-button type="primary" size="small" @click="onExport">导出</el-button>-->
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table border :data="tableData" size="mini" id="table" ref="table">
                <el-table-column type="index" label="序号" />
                <el-table-column label="机构组" prop="deptGroupName" v-if="showColumn('deptGroupName')" />
                <el-table-column label="机构" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column
                    label="销售日期"
                    prop="sellDate"
                    key="sellDate"
                    width="140"
                    v-if="showColumn('sellDate')"
                >
                    <template slot-scope="scope">
                        <span>{{ formatDate(new Date(scope.row.sellDate), 'yyyy-MM-dd hh:mm:ss') }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="商品计划" prop="merchandisingName" v-if="showColumn('merchandisingName')" />
                <el-table-column label="销售数量" prop="count" v-if="showColumn('count')" />
                <el-table-column label="销售总数(含赠品)" prop="totalCount" v-if="showColumn('totalCount')" />
                <el-table-column label="销售金额" prop="tradeMoney" key="tradeMoney" v-if="showColumn('tradeMoney')">
                    <template slot-scope="scope">
                        <span>{{ scope.row.tradeMoney | moneyToYuan }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="消费会员" prop="member" v-if="showColumn('member')" />
                <el-table-column label="消费会员电话" prop="mobile" width="140" v-if="showColumn('mobile')" />
            </el-table>
            <ef-pagination :total="page.total" @pageChange="pageChange" :default-limit="form.size" />
            <!--            当页汇总-->
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
    </div>
</template>

<script>
import UrlUtils from '../../../js/UrlUtils';
import qs from 'qs';
import Utils from '../../../js/Util';
import EfPagination from '../../components/EfPagination';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'GoodsProject',
    components: { EfPagination, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            page: {
                total: 0,
                pageSizes: Utils.PageSizes,
                PageStyle: Utils.PageStyle,
            },
            tableData: [],
            sellDate: '',
            form: {
                mobile: '',
                deptGroupCode: '',
                deptCode: '',
                merchandisingCode: '',
                startDateStr: '',
                endDateStr: '',
                page: 0,
                size: Utils.DefaultPageSize,
            },
            url: {
                queryGroups: '/system/deptGroup/staffManagedAllDeptGroups',
                queryDeptByGroup: '/system/dept/deptTree',
                queryMerchandising: '/merchandising/list',
                query: '/report/gp/flow',
            },
            meta: {
                deptGroups: [],
                depts: [],
                merchandising: [],
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ],
            },
        };
    },
    mounted() {
        UrlUtils.DeptGroup(this, (rst) => {
            const a = rst.filter((a) => a.deptType == 3);
            this.meta.deptGroups = a || [];
            if (this.meta.deptGroups.length > 0) {
                this.form.deptGroupCode = this.meta.deptGroups[0].code;
                this.handleChangeGroup(this.form.deptGroupCode);
                this.handleQuery();
            }
            this.loadingDeptGroupFlag = false;
        });
    },
    methods: {
        handleChangeGroup(value) {
            const _this = this;
            this.form.deptCode = '';
            this.form.merchandisingCode = '';
            UrlUtils.QueryRemote(this, this.url.queryMerchandising + '?deptGroupCode=' + value, (rst) => {
                _this.meta.merchandising = rst || [];
            });
            if (_this.meta.depts[value]) {
                return _this.meta.depts[value];
            }
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + value, (rst) => {
                _this.meta.depts = rst || [];
                if (_this.meta.depts.length == 1) {
                    _this.form.deptCode = _this.meta.depts[0].code;
                }
            });
        },

        handleQuery() {
            this.form.page = this.page.page;
            this.form.size = this.page.size;
            this.form.startDateStr = this.sellDate ? this.sellDate[0] : '';
            this.form.endDateStr = this.sellDate ? this.sellDate[1] : '';

            UrlUtils.QueryRemote(this, this.url.query + '?' + qs.stringify(this.form), (data) => {
                this.tableData = data.data;
                this.page.total = data.count;
            });
        },
        onExport() {},
        handlePrint() {
            this.printHTML('table', this.$route.meta.title);
        },
        pageChange(page, size) {
            this.form.page = page;
            this.form.size = size;
            if (!this.form.deptGroupCode) {
                return;
            }
            this.handleQuery();
        },
    },
};
</script>
